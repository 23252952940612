import React from "react";
import { Link } from "react-router-dom";

const Orders = () => {
  const [orderIds] = React.useState(() => {
    return JSON.parse(localStorage.getItem("orderIds")) || [];
  });

  if (orderIds.length === 0) {
    return <div>No orders made</div>;
  }

  return (
    <div className="orders">
      <nav>
        <Link to="/">New order</Link> | <Link to="/orders">My orders</Link>
      </nav>
      Orders list{" "}
      <ol>
        {orderIds.map((orderId) => (
          <li>
            <Link to={`/orders/${orderId}`}>{orderId}</Link>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Orders;
