import React, { Fragment, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { DateTime } from "luxon";
import Order from "./Order";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, Marker, ZoomControl, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import "./NewOrder.css";
import TaltechLogo from './taltech_logo.png'
import IconBus from './icon_bus.png'
import { FaQuestion } from 'react-icons/fa';

const API_URL = "https://maas-ui.vedas.ee/api/v1";

const markerIcon = new L.icon({
  iconUrl: require("./icon_bus.png"),
  iconSize: [50, 50],
  iconAnchor: [50 / 2, 40]
})

const stopIcon = new L.icon({
  iconUrl: require("./icon_stop.png"),
  iconSize: [50, 50],
  iconAnchor: [50 / 2, 40]
})

const onSubmit = async ({
  originLocation: { value: origin },
  destinationLocation: { value: destination },
  ...rest
}) => {
  const { data } = await axios.post(`${API_URL}/trip`, {
    ...rest,
    travelTime: DateTime.fromJSDate(new Date(rest.travelTime)).toISO(),
    originLocation: {
      latitude: origin.latitude,
      longitude: origin.longitude,
    },
    destinationLocation: {
      latitude: destination.latitude,
      longitude: destination.longitude,
    },
  });
  return data;
};

const fetchLocations = async (inputValue, callback) => {
  const response = await axios.get(
    `https://inaadress.maaamet.ee/inaadress/gazetteer?address=${inputValue}`
  );
  if (!response.data.addresses) {
    return [];
  }
  callback(
    response.data.addresses.map((rawAddress) => ({
      value: {
        latitude: parseFloat(rawAddress.viitepunkt_b),
        longitude: parseFloat(rawAddress.viitepunkt_l),
        address: rawAddress.ipikkaadress,
      },
      label: rawAddress.ipikkaadress,
    }))
  );
};

const NewOrder = () => {
  let submit

  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  }

  const [trips, setTrips] = React.useState([]);
  const [tripData, setTripData] = React.useState(null);
  const [tripTime, setTripTime] = React.useState(new Date(new Date().addHours(2).toString().split('GMT')[0] + ' UTC').toISOString().split(':')[0] + ':' + new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split(':')[1]);
  const [tripMinTime, setTripMinTime] = React.useState(new Date(new Date().addHours(2).toString().split('GMT')[0] + ' UTC').toISOString().split(':')[0] + ':' + new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split(':')[1]);

  const [busLocLat, setBusLocLat] = useState(0);
  const [busLocLong, setBusLocLong] = useState(0);

  const [readyNavigate, setReadyNavigate] = useState(false);

  const [map, setMap] = useState(null);

  const navigate = useNavigate()

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  const validate = (values) => {
    const errors = {};
    if (!values.originLocation) {
      errors.originLocation = "Required";
    }
    if (!values.destinationLocation) {
      errors.destinationLocation = "Required";
    }

    //Navigate button active status
    if (values.originLocation && values.destinationLocation) {
      setReadyNavigate(true)
    }
    else {
      setReadyNavigate(false)
    }
    return errors;
  };

  const getLiveLoc = async () => {
    try {
      const liveLoc = await axios.get("https://api.ingmarsell.com/")
      let lat = liveLoc.data[0].jason_data.latitude
      let long = liveLoc.data[0].jason_data.longitude

      setBusLocLat(lat)
      setBusLocLong(long)

      //Random wiggle
      // setBusLocLat(lat + getRandomArbitrary(0, 0.0005))
      // setBusLocLong(long + getRandomArbitrary(0, 0.0005))

      //console.log(`Bus coordinates: ${lat}, ${long}`)
    } catch (err) { console.error(err.message); }
  };

  useEffect(() => {
    getLiveLoc()
    const interval = setInterval(() => {
      getLiveLoc()
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  if (trips.length > 0 && tripData) {
    return <Order trips={trips} tripData={tripData} isShowConfirm setTrips={setTrips} setTripData={setTripData} />;
  }

  return (
    <div className="wrapper">
      <link rel="stylesheet" href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css" />
      <div className="wrapper">
        <div className="order-input-wrapper">
          <div className="order-input">
            <h2 className="header">MaaS XT Pilot</h2>
            <Form
              onSubmit={async (values) => {
                //Force Departure, now
                values.travelTimeType = "DEPARTURE"
                values.travelTime = tripTime
                console.log("TRAVELTIME")
                console.log(values.travelTime)
                setTrips(await onSubmit(values));
                setTripData(values);
              }}
              validate={validate}
              initialValues={{ travelTimeType: "DEPARTURE" }}
              render={({ handleSubmit, submitting }) => {
                submit = handleSubmit
                return (
                  <form onSubmit={handleSubmit} id="exampleForm">
                    <Field name="originLocation">
                      {({ input, meta }) => (
                        <div>
                          <AsyncSelect
                            {...input}
                            loadOptions={fetchLocations}
                            isClearable
                            placeholder={"Departure"}
                            className="order-input-select"
                          />
                        </div>
                      )}
                    </Field>
                    <Field name="destinationLocation">
                      {({ input, meta }) => (
                        <div>
                          <AsyncSelect
                            {...input}
                            loadOptions={fetchLocations}
                            isClearable
                            placeholder={"Destination"}
                            className="order-input-select"
                          />
                        </div>
                      )}
                    </Field>
                  </form>
                )
              }
              }
            />
            <input type="datetime-local" name="departure-time" onChange={event => {

              let min = DateTime.fromISO(tripMinTime)
              let current = DateTime.fromISO(event.target.value)

              console.log(`min: ${min}`)
              console.log(`current: ${current}`)

              if (min > current) {
                setTripTime(tripMinTime)
                console.log("override")
              }
              else {
                console.log("set")
                setTripTime(event.target.value)
              }
            }} min={tripMinTime} value={tripTime}></input>
          </div>
        </div>

        <img className="floating-logo" src={TaltechLogo} alt="" />

        <div className="fab-container">
          <div className="button iconbutton" onClick={event => {
            window.location.href = 'https://autolab.taltech.ee/maas-xt-ui-abi/';
          }}>
            <i className="fa-solid fa-plus"><FaQuestion></FaQuestion></i>
          </div>
        </div>

        <MapContainer
          className="markercluster-map"
          center={[59.44423550789687, 24.755550314198075]} //Sadam
          //center={[59.384417, 24.808346]} //Jarvekula
          zoom={16} //Sadam
          //zoom={15} //Jarvekula
          zoomControl={false}
          maxZoom={18}
          whenCreated={setMap}
        >
          {/* <TileLayer
            url="https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=wOtNhhsAAPDcsKOs9IFuKPiUfo66AksKXjZJRB0NER8sDOU191re3Dx2sG4T7QJw"
            attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          /> */}

          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            attribution='Tiles &copy; Esri'
          />

          {/* Live bus location */}
          <Marker position={[busLocLat, busLocLong]} icon={markerIcon} />

          {/* Sadam */}
          <Marker position={[59.44420956785963, 24.753257997026065]} icon={stopIcon} />
          <Marker position={[59.44432120871477, 24.757885383623428]} icon={stopIcon} />

          {/* Jarvekula */}
          <Marker position={[59.383528, 24.802696]} icon={stopIcon} />
          <Marker position={[59.384370, 24.801920]} icon={stopIcon} />
          <Marker position={[59.385832, 24.802797]} icon={stopIcon} />
          <Marker position={[59.385832, 24.805386]} icon={stopIcon} />
          <Marker position={[59.385392, 24.806507]} icon={stopIcon} />
          <Marker position={[59.384417, 24.808346]} icon={stopIcon} />
          <Marker position={[59.382891, 24.810223]} icon={stopIcon} />
          <Marker position={[59.383517, 24.811459]} icon={stopIcon} />
          <Marker position={[59.384670, 24.813509]} icon={stopIcon} />
          <Marker position={[59.385964, 24.815483]} icon={stopIcon} />
        </MapContainer>
      </div>

      <div className="bottom-wrapper">

        <button
          className="trips-button"
          onClick={event => {
            map.flyTo([59.44423550789687, 24.755550314198075], 16)
          }}
        >
          Sadam
        </button>

        <button
          className="trips-button"
          onClick={event => {
            map.flyTo([59.384417, 24.808346], 15)
          }}
        >
          Järveküla
        </button>

        <button
          className="trips-button"
          onClick={event => {
            navigate('/orders');
          }}
        >
          Trips
        </button>

        <button
          type="submit"
          className={`go-button ${readyNavigate ? "go-button-active" : ""}`}
          onClick={event => {
            setReadyNavigate(false)
            submit(event)
          }}
        >
          Navigate
        </button>

      </div>
    </div>

  );
};

export default NewOrder;
