import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
import NewOrder from "./NewOrder";
import Orders from "./Orders";
import OrderDetails from "./OrderDetails";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter >
      <Routes>
        <Route path="/orders/:id" element={<OrderDetails />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/" element={<NewOrder />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
