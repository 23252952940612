import React, { Fragment } from "react";
import axios from "axios";
import { intervalToDuration, formatDuration } from "date-fns";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
const API_URL = "https://maas-ui.vedas.ee/api/v1";

const Order = ({ tripData, trips, isShowConfirm, setTrips, setTripData }) => {
  const navigate = useNavigate();

  if (trips.length === 0 || !tripData) {
    return null;
  }

  return (
    <div className="order">
      <div>
        Travelling from <i>{tripData?.originLocation?.label}</i> to{" "}
        <i>{tripData?.destinationLocation?.label}</i>
      </div>
      {trips.map((trip, i) => (
        <Fragment key={i}>
          <ul>
            <li>
              Duration:{" "}
              {formatDuration(
                intervalToDuration({
                  start: 0,
                  end: trip.durationSeconds * 1000,
                }),
                {
                  format: ["hours", "minutes"],
                }
              )}
            </li>
            <li>
              Origin: {trip.originLocationName}{" "}
              {DateTime.fromISO(trip.originTime).toFormat("dd.MM HH:mm")}
            </li>
            <li>
              Destination: {trip.destinationLocationName}{" "}
              {DateTime.fromISO(trip.destinationTime).toFormat("dd.MM HH:mm")}
            </li>
            <li>
              Legs:
              {trip.legs.map((leg) => (
                <ul>
                  <li>
                    {leg.originLocationName}{" "}
                    {DateTime.fromISO(leg.originTime).toFormat("HH:mm")} -{" "}
                    {leg.destinationLocationName}{" "}
                    {DateTime.fromISO(leg.destinationTime).toFormat("HH:mm")} (
                    {formatDuration(
                      intervalToDuration({
                        start: 0,
                        end: leg.durationSeconds * 1000,
                      }),
                      {
                        format: ["hours", "minutes", "seconds"],
                      }
                    )}
                    ) [{leg.traverseDescription?.type}]
                    <br />{" "}
                    {leg.traverseDescription?.vehicleIdentifier && (
                      <span>
                        <b>Vehicle identifier:</b>{" "}
                        {leg.traverseDescription?.vehicleIdentifier}
                      </span>
                    )}{" "}
                    {leg.traverseDescription?.operatorName && (
                      <span>
                        <b>Operator:</b> {leg.traverseDescription?.operatorName}
                      </span>
                    )}
                  </li>
                </ul>
              ))}
            </li>
          </ul>
          {isShowConfirm && (
            <>
              <button
                type="button"
                onClick={async () => {
                  const { data } = await axios.post(
                    `${API_URL}/trip/confirm`,
                    trip
                  );
                  localStorage.setItem(
                    "orderIds",
                    JSON.stringify([
                      ...(JSON.parse(localStorage.getItem("orderIds")) || []),
                      data.tripId,
                    ])
                  );
                  localStorage.setItem(
                    data.tripId,
                    JSON.stringify({
                      originLocation: {
                        label: tripData?.originLocation?.label,
                      },
                      destinationLocation: {
                        label: tripData?.destinationLocation?.label,
                      },
                      travelTime: "2022-03-31T20:31",
                    })
                  );
                  navigate(`/orders/${data.tripId}`);
                }}
              >
                Choose this trip
              </button>
              <hr />
            </>
          )}
        </Fragment>
      ))}
      <div className="bottom-wrapper-order">
        <button
          className="trip-back-button"
          onClick={event => {
            try{
              setTrips([])
              setTripData(null)
            }
            catch{}
            navigate('/');
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default Order;
