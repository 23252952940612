import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Order from "./Order";
import { Link } from "react-router-dom";
const API_URL = "https://maas-ui.vedas.ee/api/v1";

const OrderDetails = () => {
  const { id } = useParams();
  const [trip, setTrip] = React.useState(null);
  const [tripData, setTripData] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`${API_URL}/trip/${id}`);
      setTrip(data);
    }
    setTripData(JSON.parse(localStorage.getItem(id)));
    fetchData();
  }, [id]);

  return (
    <div className="order-details">
      <nav>
        <Link to="/">New order</Link> | <Link to="/orders">My orders</Link>
      </nav>
      <h3>Order details for #{id}</h3>
      {trip && <Order trips={[trip]} tripData={tripData} />}
    </div>
  );
};

export default OrderDetails;
